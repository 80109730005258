import styled from 'styled-components';
import tw from 'tailwind.macro';
import { math } from 'polished';

import { theme } from '../../../tailwind.config.js';

export const LogoWrapper = styled.div`
  ${tw`mx-auto`};

  @media (min-width: 1250px) {
    ${tw`absolute left-0 ml-48`};
  }
`;

export const Navigation = styled.nav`
  ul {
    ${tw`justify-center flex flex-row flex-wrap mt-24`};

    @media (min-width: 1250px) {
      ${tw`mt-0`};
    }
  }

  li {
    ${tw`py-8 px-16`};

    @media (min-width: 1250px) {
      ${tw`py-0`};
    }
  }
`;

export const Wrapper = styled.header`
  ${tw`flex flex-col font-bold font-serif justify-center text-sm text-purple-300 pb-48 px-16 pt-32 uppercase`};
  line-height: ${math('18/14')};

  @media (min-width: 1250px) {
    ${tw`flex-row pb-144 px-0 pt-48`};
  }
`;
