import styled from 'styled-components';
import tw from 'tailwind.macro';
import { math } from 'polished';

export const Wrapper = styled.footer`
  ${tw`bg-purple-200 flex-center flex py-144 text-center`};

  svg {
    ${tw`mb-48 mx-auto`};
  }
`;

export const Paragraph = styled.p`
  ${tw`font-sans mb-48 text-sm text-grey`};
  line-height: ${math('22/17')};

  &:last-child {
    ${tw`mb-0`};
  }
`;

export const Navigation = styled.nav`
  ul {
    ${tw`flex flex-wrap justify-center mb-48`};
  }

  li {
    ${tw`font-sans text-sm text-grey px-16`};
  }
`;
