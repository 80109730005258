import styled from 'styled-components';
import tw from 'tailwind.macro';

import { theme } from '../../tailwind.config.js';

export const Column = styled.div`
  ${tw`mb-32 px-16 relative w-full`};

  @media (min-width: ${theme.screens.md}) {
    ${tw`mb-0`};
    ${props => (props.orderFirstDesktop ? tw`order-first` : tw``)};
    ${props =>
      (props.width === '1/2' && tw`w-1/2`) ||
      (props.width === '1/3' && tw`w-1/3`) ||
      (props.width === '2/3' && tw`w-2/3`) ||
      (props.width === '1/4' && tw`w-1/4`) ||
      (props.width === '2/4' && tw`w-2/4`) ||
      (props.width === '3/4' && tw`w-3/4`) ||
      (props.width === '1/5' && tw`w-1/5`) ||
      (props.width === '2/5' && tw`w-2/2`) ||
      (props.width === '3/5' && tw`w-3/5`) ||
      (props.width === '4/5' && tw`w-4/5`) ||
      (props.width === '1/6' && tw`w-1/6`) ||
      (props.width === '2/6' && tw`w-2/6`) ||
      (props.width === '3/6' && tw`w-3/6`) ||
      (props.width === '4/6' && tw`w-4/6`) ||
      (props.width === '5/6' && tw`w-5/6`) ||
      (props.width === '1/12' && tw`w-1/12`) ||
      (props.width === '2/12' && tw`w-2/12`) ||
      (props.width === '3/12' && tw`w-3/12`) ||
      (props.width === '4/12' && tw`w-4/12`) ||
      (props.width === '5/12' && tw`w-5/12`) ||
      (props.width === '6/12' && tw`w-6/12`) ||
      (props.width === '7/12' && tw`w-7/12`) ||
      (props.width === '8/12' && tw`w-8/12`) ||
      (props.width === '9/12' && tw`w-9/12`) ||
      (props.width === '10/12' && tw`w-10/12`) ||
      (props.width === '11/12' && tw`w-11/12`) ||
      (props.width === '12/12' && tw`w-12/12`)}
  }

  &:last-child {
    ${tw`mb-0`};
  }
`;

export const Container = styled.div`
  ${tw`mx-auto px-16 w-full`};

  @media (min-width: ${theme.screens.sm}) {
    max-width: ${theme.screens.sm};
  }
  @media (min-width: ${theme.screens.md}) {
    max-width: ${theme.screens.md};
  }
  @media (min-width: ${theme.screens.lg}) {
    max-width: ${theme.screens.lg};
  }
`;

export const Row = styled.div`
  ${tw`flex-wrap mb-32 -mx-16`};
  ${props => (props.hideMobile ? tw`hidden` : tw`flex`)};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  ${props =>
    (props.textAlign === 'left' && tw`text-left`) ||
    (props.textAlign === 'center' && tw`text-center`) ||
    (props.textAlign === 'right' && tw`text-right`)}

    @media (min-width: ${theme.screens.md}) {
      ${tw`flex`};
      ${props => (props.hideDesktop ? tw`hidden` : tw``)};
    }
`;
